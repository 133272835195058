import { environments, envs } from '@sentry/shared';
import { getAnalyticsStore, getApplicationState } from '../../store';
import { IMS_CLIENT_PROP, PAGE_INFO_PROP } from './constants';
import { AnalyticsEvent } from './events';

const { byEnvironment, ENVIRONMENTS } = environments;

const marketingConfig = byEnvironment(envs.getAppEnv);
export const getLaunchUrl = () =>
  marketingConfig<string>({
    [ENVIRONMENTS.local]: () =>
      'https://assets.adobedtm.com/d4d114c60e50/9893a31a89dc/launch-20e1cb549f8d-development.min.js',
    [ENVIRONMENTS.stage]: () =>
      'https://assets.adobedtm.com/d4d114c60e50/9893a31a89dc/launch-60f5ad31dcdc-staging.min.js',
    [ENVIRONMENTS.prod]: () =>
      'https://assets.adobedtm.com/d4d114c60e50/9893a31a89dc/launch-1b1712458f6e.min.js',
  });

export const getEdgeConfigId = () =>
  marketingConfig<string>({
    [ENVIRONMENTS.local]: () => '907a6882-ab0e-470b-b9e2-dacfef7a4b56',
    [ENVIRONMENTS.stage]: () => '907a6882-ab0e-470b-b9e2-dacfef7a4b56',
    [ENVIRONMENTS.prod]: () => '6f975362-e0ca-4f3a-9e2c-8d45aff664ac',
  });

window.marketingtech = {
  adobe: {
    launch: {
      url: getLaunchUrl(),
      controlPageLoad: true,
    },
    alloy: {
      edgeConfigId: getEdgeConfigId(),
    },
    target: false,
    audienceManager: false,
  },
};

function handleAnalyticsEvents(events: AnalyticsEvent[]) {
  events.forEach(event => {
    event.cycle();
  });
}

import('./lib-fetch').then(async initialize => {
  // Initialize library code
  await initialize.default();
  const analyticsStore = getAnalyticsStore();

  // Set up debounced handler for the events and set loading done
  analyticsStore.setState({ isAnalyticsLoading: false });

  // Handle events that might have accumulated meanwhile

  const appState = await getApplicationState();
  const {
    queryState: { ac, client_id, locale },
    config: { variant },
  } = appState;

  const analyticsLocaleFormat = locale.replaceAll('_', '-');
  window.alloy_all.set(`${PAGE_INFO_PROP}.language`, analyticsLocaleFormat);
  window.alloy_all.set(`${PAGE_INFO_PROP}.siteSection`, 'IMS-Light');
  window.alloy_all.set(`${PAGE_INFO_PROP}.version`, '2.0 Light');

  window.alloy_all.set(`${IMS_CLIENT_PROP}.clientID`, client_id);
  if (ac) {
    window.alloy_all.set(`${IMS_CLIENT_PROP}.appCodeValue.ac`, ac);
  }

  // Handle all events that have stacked up until now
  const { takeEvents } = analyticsStore.getState();
  handleAnalyticsEvents(takeEvents());

  // Handle future events
  analyticsStore.subscribe(state => {
    const { takeEvents } = state;

    const events = takeEvents();
    if (events.length > 0) {
      handleAnalyticsEvents(events);
    }
  });
});
